// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../resources/fonts/NotoSans-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../resources/fonts/NotoSans-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../resources/fonts/NotoSans-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../resources/fonts/NotoSans-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../resources/fonts/NotoSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../resources/fonts/NotoSans-SemiBold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:noto-sans-bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-extrabold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-light;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-medium;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-regular;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-semibold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");font-weight:400;font-style:normal}.restriction-transaction-modal-container[data-v-1696625f]{overflow-y:auto}.restriction-transaction-modal-container[data-v-1696625f] .ivu-modal{width:12rem!important}.restriction-transaction-modal-container[data-v-1696625f] .ivu-modal .auto-complete-size{width:100%}.restriction-transaction-modal-container .body-wrapper[data-v-1696625f]{display:grid;max-height:none!important;grid-template-columns:20% 80%;padding:0!important}.main-container[data-v-1696625f]{padding:.25rem .25rem 0 .25rem;height:60vh;min-height:4rem;max-height:6.5rem}.tab-footer[data-v-1696625f]{display:block;text-align:right}.title[data-v-1696625f]{font-weight:600;color:#44004e;font-family:noto-sans-regular;font-size:.18rem}.restriction-wrapper[data-v-1696625f]{height:100%;display:grid;grid-template-rows:.5rem auto .5rem}.nav-links-wrapper[data-v-1696625f]{border-right:.02rem solid #d3d3d3}.nav-links-wrapper .header-container[data-v-1696625f]{padding-left:.25rem;padding-top:.25rem;padding-bottom:.45rem}.nav-links-wrapper .header-container .title-style[data-v-1696625f]{color:#44004e;font-size:.18rem;font-family:noto-sans-semibold}.nav-links-wrapper .header-container .nav-wrapper[data-v-1696625f]{height:100%}", ""]);
// Exports
module.exports = exports;
