





























































import { AccountRestrictionsListTs } from './AccountRestrictionsListTs';
export default class AccountRestrictionsList extends AccountRestrictionsListTs {}
