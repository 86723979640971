





































import UploadAddressBookTs from './UploadAddressBookTs';

export default class UploadAddressBook extends UploadAddressBookTs {}
