













































































































import { ModalAccountRestrictionsTs } from './ModalAccountRestrictionsTs';
export default class ModalAccountRestrictions extends ModalAccountRestrictionsTs {}
