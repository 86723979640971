

































































































import { ModalFormSubAccountCreationTs } from './ModalFormSubAccountCreationTs';
export default class ModalFormSubAccountCreation extends ModalFormSubAccountCreationTs {}
