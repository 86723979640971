









import { ModalContactCreationTs } from './ModalContactCreationTs';
export default class ModalContactCreation extends ModalContactCreationTs {}
