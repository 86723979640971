




















































































import { FormAccountRestrictionTransactionTs } from './FormAccountRestrictionTransactionTs';
export default class FormAccountRestrictionTransaction extends FormAccountRestrictionTransactionTs {}
