







































































import { AccountsTs } from './AccountsTs';
export default class Accounts extends AccountsTs {}
