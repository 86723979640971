import { render, staticRenderFns } from "./UploadAddressBook.vue?vue&type=template&id=7dab1ed4&scoped=true&"
import script from "./UploadAddressBook.vue?vue&type=script&lang=ts&"
export * from "./UploadAddressBook.vue?vue&type=script&lang=ts&"
import style0 from "./UploadAddressBook.vue?vue&type=style&index=0&id=7dab1ed4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dab1ed4",
  null
  
)

export default component.exports