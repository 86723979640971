
























// extenal dependencies
import { Component, Prop, Vue } from 'vue-property-decorator';

// child components
import FormRow from '@/components/FormRow/FormRow.vue';

export enum RestrictionDirection {
    INCOMING = 'incoming',
    OUTGOING = 'outgoing',
}

@Component({ components: { FormRow } })
export default class RestrictionDirectionInput extends Vue {
    @Prop({ default: false }) readonly disabled!: boolean;
    @Prop({ default: RestrictionDirection.OUTGOING }) readonly value!: RestrictionDirection;

    onDirectionChange(newValue) {
        this.$emit('input', newValue);
    }
}
