















































































import { ContactSelectorPanelTs } from './ContactSelectorPanelTs';
import './ContactSelectorPanel.less';

export default class ContactSelectorPanel extends ContactSelectorPanelTs {}
