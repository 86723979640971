











































































import { FormContactCreationTs } from './FormContactCreationTs';
export default class FormContactCreation extends FormContactCreationTs {}
