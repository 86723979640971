








import { ModalImportAddressBookTs } from './ModalImportAddressBookTs';

export default class ModalImportAddressBook extends ModalImportAddressBookTs {}
