





























import { ModalBackupReminderTs } from './ModalBackupReminderTs';
export default class ModalBackupReminder extends ModalBackupReminderTs {}
