


































































































































import { AccountSelectorPanelTs } from './AccountSelectorPanelTs';
import './AccountSelectorPanel.less';

export default class AccountSelectorPanel extends AccountSelectorPanelTs {}
