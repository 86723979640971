





























// extenal dependencies
import { Component, Prop, Vue } from 'vue-property-decorator';

// child components
import FormRow from '@/components/FormRow/FormRow.vue';

export enum RestrictionBlockType {
    BLOCK = 'block',
    ALLOW = 'allow',
}

@Component({ components: { FormRow } })
export default class RestrictionTypeInput extends Vue {
    @Prop({ default: false }) readonly disabled!: boolean;
    @Prop({ default: RestrictionBlockType.BLOCK }) readonly value!: RestrictionBlockType;

    onBlockTypeChange(newValue) {
        this.$emit('input', newValue);
    }
}
