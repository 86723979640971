


























import { ModalBackupProfileTs } from './ModalBackupProfileTs';

export default class ModalBackupProfile extends ModalBackupProfileTs {}
