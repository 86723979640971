










import OperationRestrictionTransactionTypeSelectorTs from './OperationRestrictionTransactionTypeSelectorTs';

export default class OperationRestrictionTransactionTypeSelector extends OperationRestrictionTransactionTypeSelectorTs {}
